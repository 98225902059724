<template>
  <div>
    <Navbar text="Découvrez votre budget formation entreprise !"/>

    <div class="is-max-desktop content is-vcentered " style="padding-bottom: 20px; margin-bottom: 0 ; margin-left: 10%; margin-right: 10%;" >

      <br/>
      <p class="has-text-centered" style="font-family: 'Montserrat Alternates', sans-serif; color: black; font-weight: bold; font-size: x-large">Saisissez le nom de votre société ou votre n°SIREN</p>

      <br/>
      <div style="">
        <form class="center-block" style="">
          <b-field>
            <b-input placeholder="Nom de la société ou N°SIREN"
                     type="search"
                     icon-pack="fas"
                     icon="search"
                     v-model="companyName"
                     rounded
                     class="input-companyName"
            >
            </b-input>
            <p style="">
              <b-button native-type="submit" type="is-primary"  @click="getCompanyOptions" label="Rechercher" style="" />
              <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true" style="">
                <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin" style="place-content: center !important">
                </b-icon>
              </b-loading>
            </p>
          </b-field>
        </form>
      </div>


      <div>
        <AutocompleteIdcc @onChange="handleIdccChange" :hidden="knowIdcc"/>
        
      </div>

      <br/>

      <div v-if="result && result.length > 0 ">
        <p style="margin-left :33px;">Résultat(s) de votre recherche :</p>
        <ul v-for="res in result" :key="res.siren">
          <li class="is-mgb-3 li-home" style="margin-bottom: 10px; padding-top: 13px;" @click="selectCompany(res)" ><i class="far fa-building fa-spin"></i> <span style="font-weight: bold">{{res.nom_entreprise}} </span>   <span style="font-weight: lighter; font-size: smaller">{{res.siege ? res.siege.adresse_ligne_1 : ""}} {{res.siege ? res.siege.adresse_ligne_2 : ""}} {{res.siege ? res.siege.code_postal : ""}}</span> {{res.conventions_collectives.length ? "✅" : ""}}  </li>
        </ul>
        <br/>
      </div>
      <ul v-else-if="result">
        <li class="content li-home" style="padding: 7px; background-color: rgba(173,207,212,0.45)"> Désolé, nous n'avons pas trouvé votre entreprise, essayez une autre orthographe ou vérifiez le n°SIREN</li>
      </ul>

    </div>

    <div class="card card-content is-max-desktop content is-vcentered" style=" margin-top: 0; ">
      <div class="is-vcentered has-text-centered"  style=" margin-left: 10%; margin-right: 10%; margin-bottom: 50px; padding-bottom: 20px; padding-top: 20px; border-bottom: solid; border-top: solid;border-top-color: #3534A1;border-bottom-color: #3534A1; border-top-width: thick; border-bottom-width: thick">
        <p class="title is-4" style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1; margin-bottom: 40px">
          Mon budget formation, le meilleur moyen de se faire financer et savoir comment.
        </p>
        <p class="subtitle is-6" style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1">Il est complexe et souvent méconnu. Mais c'est pourtant un atout essentiel pour le développement de votre entreprise.
          Recherchez et activez les meilleurs dispositifs pour vos projets de formation.</p>
      </div>


      <div class="columns" style="margin-left: 10%; margin-right: 10%; margin-bottom: 50px">

        <div class="column" style="margin-top: 20px">
          <p class="title is-4" style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1 ; margin-bottom: 40px">
            La liste des budgets financiers accessibles en ligne !
          </p>
          <p class="subtitle is-6" style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1">Notre effort s'attache à développer les meilleures pratiques et à mettre en place un cadre de transparence,
            afin de vous faciliter l'accès aux données présentes sur notre site Internet.</p>
          <p style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1">🏛️  Plus de 350 organismes financeurs référencés</p>
          <p style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1">🚀  Très facile et rapide</p>
          <p style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1">🧿️  Des résultats ultra-personnalisés pour chacun de vos projets</p>

        </div>

        <div class="column">
          <img src="@/assets/finantialList.png" alt="..." style="border-left: solid; border-left-color:#3534A1; border-left-width: thick ; box-shadow: 1px 1px 10px #5e6578;">
        </div>

      </div>


      <div class="columns" style="margin-left: 10%; margin-right: 10%">

        <div class="column">
          <img src="@/assets/undraw_add_information_j2wg.png" alt="..." style="border-right: solid; border-right-color:#3534A1; border-right-width: thick ;box-shadow: 1px 1px 10px #5e6578; ">
        </div>

        <div class="column" style="margin-top: 20px">
          <p class="title is-4" style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1 ; margin-bottom: 40px">
            Les données les plus actualisées et claires possible
          </p>
          <p class="subtitle is-6" style="font-family: 'Montserrat Alternates', sans-serif; color: #3534A1">Nous œuvrons quotidiennement pour proposer un service accessible à tous,
            qui simplifiera et fluidifiera le circuit du financement institutionnel.</p>


        </div>

      </div>


    </div>

    <Footer/>


  </div>


</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import AutocompleteIdcc from "@/components/AutocompleteIdcc.vue";
import {getIdccByCompanyName} from "@/utils/pappersApi/getIdccByName";
import {setCookie} from "@/utils/cookies";
import {getIdccFromNAF} from "@/utils/idcc/getIdccFromNAF";
export default {
  name: "Home",
  components: {
    Navbar,
    AutocompleteIdcc,
    Footer
  },
  data() {
    return {
      companyName : "",
      idcc: "",
      result : '',
      isLoading: false,
      isFullPage: false,
      knowIdcc:true,
      idcc_value:null,
    };
  },

  methods:{

    handleIdccChange(idcc){
      this.idcc_value = [idcc]
      if(idcc.idcc && idcc.idcc.length>3 && idcc.nom){
        this.onChangeValue()
      }
    },

    onChangeValue() {
      this.$store.state.informations = {nom : this.result[0].nom_entreprise, date_creation : this.result[0].date_creation, forme_juridique : this.result[0].forme_juridique}
    
      this.$store.state.informations["cc"]= this.idcc_value
      setCookie("resCompany", JSON.stringify(this.$store.state.informations), 1)
      this.$router.push('/results')
    },


    openLoading() {
      this.isLoading = true
    },

    getIdccFromNAFFunction(code_naf, informations){
      getIdccFromNAF(code_naf).then( result => {
        if(result.data.length>0){
          informations["cc"]= result.data
          setCookie("resCompany", JSON.stringify(this.$store.state.informations), 1)
          this.$router.push('/results')
        } else {
          this.notificationError("Nous n'avons pas pu trouver votre IDCC")

          this.knowIdcc = !this.knowIdcc

        }
      })


    },

    getCompanyOptions(){
      event.preventDefault()
      this.openLoading()
      try {
        getIdccByCompanyName(this.companyName)
            .then(res=>{
              this.result = res.data.resultats
              this.isLoading = false
            }).catch(()=>{
              this.result=undefined
            })
      }catch (e) {
        console.log(e)
        this.result=undefined
      }
    },

    selectCompany(res){
      this.$store.state.informations = {nom : res.nom_entreprise, date_creation : res.date_creation, forme_juridique : res.forme_juridique}
      if(res.forme_juridique.split(',')[0] === "SAS"){

        if(res.conventions_collectives && res.conventions_collectives[0] && res.conventions_collectives[0].idcc && res.conventions_collectives[0].nom){
          this.notificationSuccess("Nous avons trouvé votre idcc ! Passons à la suite :)")
          this.$store.state.informations["cc"]= res.conventions_collectives
          setCookie("resCompany", JSON.stringify(this.$store.state.informations), 1)
          this.$router.push('/results')
        }else{
          if(res.code_naf){
            this.getIdccFromNAFFunction(res.code_naf, this.$store.state.informations)
          }

          //this.notificationError("Il n'y a pas d'idcc... Nous ne pouvons pas passer à la suite")
        }

      }else{

        if(res.conventions_collectives && res.conventions_collectives[0] && res.conventions_collectives[0].idcc && res.conventions_collectives[0].nom){
          this.notificationSuccess("Nous avons trouvé votre idcc ! Passons à la suite :)")
          this.$store.state.informations["cc"]= res.conventions_collectives
          setCookie("resCompany", JSON.stringify(this.$store.state.informations), 1)
          this.$router.push('/results')
        }else{
          if(res.code_naf){
            this.getIdccFromNAFFunction(res.code_naf, this.$store.state.informations)
          }

          //this.notificationError("Il n'y a pas d'idcc... Nous ne pouvons pas passer à la suite")
        }

      }

    }
  }
}
</script>
<style>
.li-home {
  list-style-type: none;
  border-top: 1px solid;
  cursor : pointer;
}
.input-companyName {
  font-family: "Montserrat Alternates", sans-serif;
  color: rgb(53, 52, 161) !important;
  width : 50vw;
  left : 30px;
}
.button.is-primary {
    position: absolute;
    width: 27vw;
    left: 61.7vw;
    height: 40px;
    background: #FF8A55;
    border-radius: 0px 36px 36px 0px;
}
</style>
